

























































import { Component, Vue } from 'vue-property-decorator';
import label from '@/resources/labels/p0113label.json';

@Component({
  components: {}
})
export default class P0113 extends Vue {
  private readonly label: any = label;
}
