










































































































































import { Component, Vue } from 'vue-property-decorator';
import * as api from '@/gen/api';
import label from '@/resources/labels/p0112label.json';

@Component({
  components: {}
})
export default class P0112 extends Vue {
  private data() {
    return {
      enabled: false
    };
  }

  private readonly label: any = label;
  private isEditable: boolean = true;

  // model
  private contents: string = '';

  /**
   * 確認ボタン押下
   */
  private confirm(): void {
    // 全項目の検証
    this.$validator.validateAll().then((result: boolean) => {
      if (result) {
        // 確認画面に変更
        this.isEditable = false;
        window.scrollTo(0, 0);
      }
    });
  }

  /**
   * 戻るボタン押下
   */
  private back(): void {
    // 入力画面に変更
    this.isEditable = true;
    window.scrollTo(0, 0);
  }

  /**
   * APIリクエスト時のbodyを作成
   */
  private get getReqBody(): any {
    return {
      contents: this.contents
    };
  }

  /**
   * 問い合わせ用メール送信API呼び出し
   */
  private async callInquireSendEmailApi(reqBody: any): Promise<void> {
    const config = await this.$getConfigWithToken(this.$auth);
    const inquireSendEmailApi = new api.InquireSendEmailApi(config);
    inquireSendEmailApi
      .inquireSendEmail(reqBody)
      .then(() => {
        this.$router.push('/contact-form-complete');
      })
      .catch(this.$tokenErrHandler);
  }
}
